const $ = window.jQuery
const draggingOptions = {}

const setupDraggable = function draggableSetup() {
  const $draggableItems = $(this).find(".ui-draggable-handle")
  $draggableItems.draggable({
    helper: (e) => {
      const name = $(e.currentTarget).text()
      return `<div class="border--main bg-white p-4 text-primary-80">${name}</div>`
    },
    start(_e, ui) {
      draggingOptions.element = this
      draggingOptions.helper = ui.helper
    },
    opacity: 0.65,
  })
}

const setupDroppable = function droppableSetup() {
  $(this)
    .find(".list-group-item")
    .droppable({
      accept: ".position-type-title",
      drop(_e, ui) {
        const jobFamilyId = $(this).data("job-family")
        const url = ui.draggable.data("update-job-family-path")

        $.ajax({
          url,
          type: "PUT",
          data: {
            move_to_job_family: jobFamilyId,
          },
          container: '[data-pjax-container="job-definitions-list"]',
        })
          .done(() => {
            $(draggingOptions.element).closest("tr").remove()

            $.pjax.reload('[data-pjax-container="job-families-list"]', {
              fragment: '[data-pjax-container="job-families-list"]',
            })
          })
          .fail(() => {
            // alert('error');
          })
      },
      activate() {
        $(this).closest(".list-group").addClass("dropzone")
      },
      deactivate() {
        $(this).closest(".list-group").removeClass("dropzone")
      },
      // NOTE: This is deprecated in new versions of jQuery UI.
      hoverClass: "ui-droppable-hover",
    })
}

function jobSubfamilySelectorChangeHandler() {
  $(this).closest("form").find("[name=move_to_job_family]").val($(this).val())
}

function jobFamilySelectorChangeHandler() {
  const selectedValue = $(this).val()
  const $container = $(this).closest(".modal-body")
  const $subfamilySelectorGroup = $container.find(`[data-parent-job-family-id='${selectedValue}']`)

  $container.find(".subfamily-selector").addClass("hidden")
  if ($subfamilySelectorGroup.size() > 0) {
    if (selectedValue) {
      $subfamilySelectorGroup.closest(".subfamily-selector").removeClass("hidden")
    }
    $subfamilySelectorGroup.trigger("change")
  } else if (selectedValue === "unassign") {
    jobSubfamilySelectorChangeHandler.call(this)
  }
}

const handleJobFamilySelector = function jobFamilyHandler() {
  $(this).on("change", jobFamilySelectorChangeHandler)
  $(this).trigger("change")
  $(this)
    .closest(".modal-body")
    .find(".subfamily-selector select")
    .on("change", jobSubfamilySelectorChangeHandler)
  $(this).closest(".modal-body").find(".subfamily-selector select:visible").trigger("change")
}

$.onmount(".positions-table-ordering", () => {
  $(".positions-table-ordering").sortable()
})
$.onmount("#position-types-table", setupDraggable)
$.onmount(".subfamilies-list", setupDroppable)
$.onmount("[data-job-family-selector]", handleJobFamilySelector)
