/* global $ App NProgress */

/**
This file varies from the v2-stubs version in a number of ways. Here, org chart
data is not referenced. Noteworthy differences have been noted in the comments
in this file, but other subtle differences may exist.
*/

import { createElement } from "react"
import { createRoot } from "react-dom/client"

import { EditMatrixSettingsButton } from "v2/react/components/successionSettings/EditMatrixSettingsButton"
import { UrlHelper } from "v2/react/utils/urls"

import { PanelSidebar } from "./panel_sidebar"

$(document).ready(initAppPreferences)

function initAppPreferences() {
  window.SuccPlanPanelApp = {
    endpoint: function (path) {
      path = _.isArray(path) ? path.join("/") : path

      return "/api/app/v1/" + path + ".json"
    },

    Preferences: {
      update: function (preferences) {
        return $.ajax({
          contentType: "application/json",
          data: JSON.stringify({ settings: preferences }),
          dataType: "json",
          type: "PUT",
          url: App.endpoint(["people", window.gon.current_person_id, "settings"]),
        })
      },
    },
  }
}

function enableNProgress() {
  $(document).on("pjax:send.nprogress", NProgress.start)
}

function onProfilePanelLinkClicked(e) {
  e.preventDefault()

  const personId = $(this).data("person-id")
  if (personId) {
    window.location.href = UrlHelper.personProfilePath(personId)
  }
}

function toggleNotes(e) {
  e.preventDefault()
  // This is different than in the orgchart/v2-stubs js. Using
  // `$(this).toggleClass("is-on")` file causes an issue here. A magical thing
  // is already doing this.
  const showNotes = $(this).hasClass("is-on")
  const $candidateNotes = $(".candidate-notes")
  showNotes ? $candidateNotes.slideDown() : $candidateNotes.slideUp()
  SuccPlanPanelApp.Preferences.update({ org_chart: { show_candidate_notes: showNotes } })
}

function openCandidateNoteForm(e) {
  e.preventDefault()

  const $notesContainer = $(this).parents(".candidate-notes")
  $notesContainer.find(".note").addClass("hidden")
  $notesContainer.find(".note-form").removeClass("hidden")
  $(this).hide()

  const editorId = $notesContainer.find("textarea.tinymce").attr("id")
  focusOnEditor(editorId)
}

function closeCandidateNoteForm(e) {
  e.preventDefault()

  const $notesContainer = $(this).parents(".candidate-notes")
  $notesContainer.find(".note").removeClass("hidden")
  $notesContainer.find(".note-form").addClass("hidden")
  $notesContainer.find("[data-action=open-candidate-note-form]").show()
  $notesContainer.find(".hide-on-error").removeClass("hidden")
  clearErrorsInContainer($notesContainer)
}

function openPlanSummaryForm(e) {
  e.preventDefault()

  const $summaryContainer = $(this).parents(".plan-summary-container")
  $summaryContainer.find(".summary").addClass("hidden")
  $summaryContainer.find(".summary-form").removeClass("hidden")
  $summaryContainer.find(".form-instructions").hide()

  const editorId = $summaryContainer.find("textarea.tinymce").attr("id")
  focusOnEditor(editorId)
}

function closePlanSummaryForm(e) {
  e.preventDefault()

  const $summaryContainer = $(this).parents(".plan-summary-container")
  $summaryContainer.find(".summary").removeClass("hidden")
  $summaryContainer.find(".summary-form").addClass("hidden")
  $summaryContainer.find(".form-instructions").show()
  $summaryContainer.find(".hide-on-error").removeClass("hidden")
  clearErrorsInContainer($summaryContainer)
}

function focusOnEditor(editorId) {
  const tinymceEditor = window.tinymce.get(editorId)
  tinymceEditor.selection.select(tinymceEditor.getBody(), true)
  tinymceEditor.selection.collapse(false)
  tinymceEditor.focus()
}

function openCandidateForm(e) {
  if (e) {
    e.preventDefault()
  }

  $(".main-candidate-form").show()
  $(".intro-message").hide()
  $(".add-candidate-button").hide()
  $("[name=person_name]").focus()
}

function clearSuccessionErrors() {
  $(".succession-plan-panel .form-error").removeClass("form-error")
  $(".succession-plan-panel .form-error-message").html("")
}

function clearErrorsInContainer($container) {
  $container.find(".form-error").removeClass("form-error")
  $container.find("form-error-message").html("")
}

function clearSelectedAndErrors() {
  $('.main-candidate-form [name="candidate[additional_info]"]').val("")
  $('.main-candidate-form [name="candidate[outsider_name]"]').val("")
  $('.main-candidate-form [name="candidate[person_id]"]').val("")
  clearSuccessionErrors()
}

function closeCandidateForm(e) {
  e.preventDefault()
  const $form = $(this).parents(".candidate-form")

  $form.hide()

  if ($form.hasClass("main-candidate-form")) {
    if ($(".candidates > .candidate").length === 0) {
      $(".intro-message").show()
    } else {
      $(".add-candidate-button").show()
    }
    $(".main-candidate-form input[name=person_name]").val("")
    clearSelectedAndErrors()
  } else {
    $form.siblings(".list-group-item").show()
  }
}

function toggleType() {
  const $form = $(this).parents("form")

  $(".main-candidate-form input[name=person_name]").val("")
  clearSelectedAndErrors()
  if (this && this.value === "within_org") {
    $form.find(".within-org-more-options").show()
    $form.find(".outside-org-more-options").hide()
  } else {
    $form.find(".within-org-more-options").hide()
    $form.find(".outside-org-more-options").show()
  }
}

function openOutsideCandidateForm(e) {
  e.preventDefault()

  const $parent = $(this).parents(".list-group-item")
  $parent.hide()
  $parent.siblings(".outside-candidate-form").show()
}

function saveCandidateProperties() {
  $(this).parents("form").submit()
}

function saveCandidateNote() {
  const $form = $(this).parents("form")
  stripEmptyFields($form)
  $form.submit()
}

function savePlanSummary() {
  const $form = $(this).parents("form")
  stripEmptyFields($form)
  $form.submit()
}

function stripEmptyFields($form) {
  // Strip empty fields of html
  const editorId = $form.find("textarea.tinymce").attr("id")
  const tinymceEditor = window.tinymce.get(editorId)
  const strippedContent = tinymceEditor.getContent({ format: "text" }).trim()
  if (!strippedContent) {
    $form.find("textarea.tinymce").val(null)
  }
}

function reorderCandidates(orderings) {
  $.pjax({
    url: $(".candidates").data("candidate-order-url"),
    data: { candidate_order: orderings },
    container: "[data-pjax-container=succession-plan-tab-content]",
    fragment: "[data-pjax-container=succession-plan-tab-content]",
    push: false,
  })
}

function reloadMatrixPanel() {
  const positionId = $(".succession-plan-panel").data("position-id")

  $.pjax.reload({
    // This is different than in the orgchart/v2-stubs js. It is important to
    // use the absolute path here.
    url: `/succession_plan_panel/${positionId}/matrix`,
    container: "[data-pjax-container='succession-plan-panel-matrix']",
    fragment: "[data-pjax-container='succession-plan-panel-matrix']",
    replace: false,
    push: false,
  })
}

function setupCandidateOrder() {
  $(".candidates").sortable({
    items: ".candidate",
    containment: "parent",
    handle: ".candidate__order",
    axis: "y",
    cursor: "move",
    tolerance: "pointer",
    stop: function (_event, ui) {
      // Reinitialize editor
      const $dragContainer = $(ui.item[0])
      const $tinymceEditors = $dragContainer.find(".tinymce")
      $tinymceEditors.each(function () {
        const editor = window.tinymce.get(this.id)
        if (editor) {
          const configOptions = $.extend({}, window.tinymceDefaults, {
            auto_focus: editor.options.get("auto_focus"),
          })
          window.tinymce.remove(editor)
          window.tinymce.init(configOptions)
        }
      })
    },
    update: function onUpdateCandidateOrder() {
      reorderCandidates($(".candidates").sortable("toArray"))
    },
  })
}

function toggleCommentButton() {
  if (this && this.value === "") {
    $(".comment-button").addClass("btn-disabled").prop("disabled", true)
  } else {
    $(".comment-button").removeClass("btn-disabled").prop("disabled", false)
  }
}

function clearCommentText(e) {
  e.preventDefault()

  $(this).parents("form").submit()
  $(".succession-plan-panel__comments-container textarea").val("")
  toggleCommentButton()
}

function switchAndOpenForm() {
  PanelSidebar.setSuccessionPlanPanelTab("summary")

  $(".succession-plan-panel").one("pjax:complete.open-form", function openCandidateFormOnce() {
    openCandidateForm()
    setTimeout(function scrollFormIntoView() {
      $(".main-candidate-form")[0].scrollIntoView({ behavior: "smooth" })
    }, 400)
  })
}

// Finds and preserves any open editors within the '.notes-container'
// or '.plan-summary-container' elements before the content replacement.
$(document).on("pjax:beforeReplace", ".succession-plan-panel-container", (event, content) => {
  const reordering = event.state.url?.includes("reorder_candidates")
  const targetedActions =
    $(event.relatedTarget).is(".candidate__properties, .new_candidate, .remove-candidate") ||
    reordering
  // Initiates the editor replacement only if the pjax event is initiated from certain actions
  // that will wipe out the editors if they're open
  if (targetedActions) {
    const $container = $(event.currentTarget)
    const $prevEditors = $container.find(".notes-container, .plan-summary-container")
    const $newEditors = content.find(".notes-container, .plan-summary-container")

    const openEditors = $prevEditors.find(".note-form, .summary-form").filter(":visible")

    if ($prevEditors.length && openEditors.length) {
      $newEditors.each((_, note) => {
        const $newEditor = $(note)
        const formElementId = $newEditor.find("form").attr("id")
        const $matchingPrevEditor = $prevEditors.filter(
          (_, prevEditor) => $(prevEditor).find("form").attr("id") === formElementId,
        )

        $matchingPrevEditor.length && $newEditor.replaceWith($matchingPrevEditor)
      })
    }
  }
})

$.onmount(".succession-plan-panel", function initSuccessionPlanPanel() {
  $(".succession-plan-panel").on("pjax:complete", enableNProgress)
})

$.onmount(".notes-container", function initCandidateNotes() {
  const $notesContainer = $(this)
  $notesContainer
    .find("[data-action=open-candidate-note-form]")
    .off("click")
    .click(openCandidateNoteForm)
  $notesContainer
    .find("[data-action=close-candidate-note-form]")
    .off("click")
    .click(closeCandidateNoteForm)
  $notesContainer.find("[data-action=save-candidate-note]").off("click").click(saveCandidateNote)
})

$.onmount(".plan-summary-container", function initPlanSummary() {
  const $summaryContainer = $(this)
  $summaryContainer
    .find("[data-action=open-plan-summary-form]")
    .off("click")
    .click(openPlanSummaryForm)
  $summaryContainer
    .find("[data-action=close-plan-summary-form]")
    .off("click")
    .click(closePlanSummaryForm)
  $summaryContainer.find("[data-action=save-plan-summary]").off("click").click(savePlanSummary)
})

$.onmount(
  ".succession-plan-panel__summary, .succession-plan-panel__matrix, .succession-compare",
  // This is different than in the orgchart/v2-stubs js. Several action and
  // behaviors are specific to the org chart.
  function initSuccessionPlanSummary() {
    $("[data-action=profile-link-clicked]").click(onProfilePanelLinkClicked)
    $("[data-action=toggle-notes]").click(toggleNotes)
    $("[data-action=open-candidate-form]").click(openCandidateForm)
    $("[data-action=close-candidate-form]").click(closeCandidateForm)
    $("[data-action=toggle-candidate-type]").change(toggleType)
    $(".main-candidate-form input[name=person_name]").on(
      "autocomplete:clearSelected",
      clearSelectedAndErrors,
    )
    $(".main-candidate-form input[name=person_name]").on(
      "autocomplete:inputChanged",
      clearSelectedAndErrors,
    )
    $('[name="candidate[outsider_name]"]').bind("input propertychange", clearSuccessionErrors)
    $("[data-action=edit-candidate]").click(openOutsideCandidateForm)
    $(".candidate__properties select").change(saveCandidateProperties)
    $(".succession-plan-panel__comments-container textarea").bind(
      "input propertychange",
      toggleCommentButton,
    )
    $(".comment-button").click(clearCommentText)
    setupCandidateOrder()
    $("[data-action=switch-and-open-form]").on("pjax:click", switchAndOpenForm)
  },
)

$.onmount(".succession-compare [data-action=set-hire-date]", function initSetHireActions() {
  const positionId = $(".succession-plan-panel").data("position-id")
  this.setAttribute(
    "href",
    this.href +
      "?reload=succession-plan-tab-content&reload_url=/succession_plan_panel/" +
      positionId +
      "/compare",
  )
})

$.onmount("#compare-settings .is-sortable", function initCompareSettingsSortable() {
  const $sortable = $(this)
  $sortable.sortable({
    update: function updateCompareRowOrder() {
      const order = $sortable.sortable("toArray", { attribute: "data-val" })
      $("#person_settings_succession_plan_panel_compare_rows_order").val(order)
    },
  })
})

$.onmount("#react-succession-settings__edit-matrix-container", function initMatrixSettings() {
  const container = $("#react-succession-settings__edit-matrix-container")[0]
  const root = createRoot(container)
  root.render(createElement(EditMatrixSettingsButton, { handleReload: reloadMatrixPanel }, null))
})

$.onmount("#compare-settings", function initCompareSettingsModalOverlay() {
  const modalRoot = $(".modal-overlay")
  // This logic is specific to v2 views, where we need to account for opening
  // this modal in a sidebar panel, which may be overlay a prior modal. (E.g.
  // the list of plans in the Succession Plan Insights).
  modalRoot.addClass("z-above-modal-modal")
})

// This is different than in the orgchart/v2-stubs js. This is only needed in
// this v2 version, since in the v2-stubs modal.js `reloadTargetContainer`
// is used much differently than in the v2 modal.js file. See:
// app/views/v2/succession_plan_panel/modals/compare_settings.html.slim
$.onmount("[data-modal=compare-settings]", function (data) {
  const selector = (data || {}).selector
  $(selector).on("modal:save", function (e, modal) {
    modal.reloadTargetContainer()
  })
})
