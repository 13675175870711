const { jQuery: $, Modal, Pjax, SelectedListGroup } = window

class ProfilePtos {
  constructor($container) {
    this.$container = $container
    this.registerViewToggles()
    ProfilePtos.registerWidgetMounts()
    ProfilePtos.registerModalMounts()
    const that = this
    $.onmount(".pto-history-selects", function initPtoTransactions() {
      that.initTransactions($(this).closest("section"))
    })
  }

  static reloadAccounts() {
    Pjax.render("section#pto-accounts", { scrollTo: false })
  }

  static reloadRequests() {
    Pjax.render("section#pto-requests", { scrollTo: false })
  }

  static balanceModals(childElement = "") {
    return [Modal.selector("pto-modify-account-balance"), Modal.selector("pto-set-balance")]
      .map((el) => el + childElement)
      .join(", ")
  }

  static registerWidgetMounts() {
    $.onmount(ProfilePtos.balanceModals(" .modal-footer"), function modifyBalanceModal() {
      const $this = $(this).parent()
      $this.find(".btn--primary:submit").on("click", (event) => {
        ProfilePtos.checkMaxBalance(event, $this)
      })
      $this.find("form").on("keypress", (event) => {
        const keyCode = event.keyCode || event.which
        if (keyCode === 13) {
          ProfilePtos.checkMaxBalance(event, $this)
          return false
        }
        return true
      })
    })
    $.onmount(
      `${Modal.selector("pto-modify-account-balance")}, ${Modal.selector("pto-set-balance")}`,
      function reloadAccountsAndTransactions() {
        $(this).one("modal:save", () => {
          ProfilePtos.reloadAccounts()
          window.pto.filterTransactions(false)
        })
      },
    )
    $.onmount(Modal.selector("confirm-max-balance-override"), function submitOverride() {
      const $this = $(this)
      $this.find(":submit").on("click", ProfilePtos.confirmMaxBalance)
      $this.find("[data-action=close]").on("click", ProfilePtos.setModifySubmit)
    })
  }

  static registerModalMounts() {
    $.onmount(
      `${Modal.selector(this.OVERVIEW_TAB_MODAL)}, ` +
        `${Modal.selector(this.PTO_TAB_MODAL)}, ` +
        `${Modal.selector(this.TIMESHEET_TAB_MODAL)}`,
      function reloadOnModalClose() {
        $(this).one("modal:save", () => {
          switch (this.dataset.modalReload) {
            case ProfilePtos.OVERVIEW_TAB_MODAL: {
              const targetContainer = Pjax.targetContainer(ProfilePtos.OVERVIEW_TAB_MODAL)
              $.pjax.reload(targetContainer, { fragment: targetContainer })
              break
            }
            case ProfilePtos.TIMESHEET_TAB_MODAL:
              window.timesheet.current.reloadPage()
              break
            case ProfilePtos.PTO_TAB_MODAL:
              ProfilePtos.reloadAccounts()
              ProfilePtos.reloadRequests()
              window.pto.filterTransactions(false)
              break
            default:
              break
          }
          delete window.ptoValidator
        })
      },
    )

    $.onmount(Modal.selector("pto-transaction-confirm-delete"), function confirmClose() {
      ProfilePtos.confirmTransactionDeletion(this)
    })

    $.onmount("form.edit_pto_request, form.new_pto_request", function modalForm() {
      const $form = $(this)

      if ($form.find(Pjax.targetContainer("calculate-hours")).length) {
        window.ptoValidator = new window.TimeOffValidator($form, window.person.dailySchedules)
      }

      $form.on("click", "button:submit, input:submit", (e) => {
        $("<input />")
          .attr("type", "hidden")
          .attr("name", "pto-action")
          .attr("value", e.currentTarget.name)
          .appendTo($(e.currentTarget.form))
      })
    })

    $.onmount('[data-action="send"]', function sendMessage() {
      $(this).on("click", (e) => {
        const $form = $(e.currentTarget.form)
        window.currentModal.clearError()

        if ($form.find(".person-list div").length === 0) {
          window.currentModal.showError("recipient is missing".t("profile", "pto_request"))
          e.preventDefault()
          e.stopPropagation()
        }
      })
    })

    $.onmount("[data-action=more-options]", function moreOptions() {
      $(this).click((e) => {
        e.preventDefault()
        if ($("[data-section=more-section]").is(":visible")) {
          $("[data-section=more-section]").addClass("hidden")
          $(".option.more").removeClass("hidden")
          $(".option.fewer").addClass("hidden")
        } else {
          $("[data-section=more-section]").removeClass("hidden")
          $(".option.more").addClass("hidden")
          $(".option.fewer").removeClass("hidden")
        }
      })
    })

    $.onmount("input[name=add_notifiers]", function sendMessage() {
      $(this).on("click", function addNotifiers() {
        if (this.checked) {
          $(this.form).attr("data-follow-up-form", true)
          $(this.form).attr("data-modal-class", "modal")
        } else {
          $(this.form).removeAttr("data-follow-up-form")
          $(this.form).removeAttr("data-modal-class")
        }
      })
    })

    $.onmount(".pto-request [data-autocomplete=person]", function searchPerson() {
      const peopleList = new SelectedListGroup($(this.form).find("div.person-list"), "recipients[]")

      $(this).on("autocomplete:selectSuggestion", function suggestionSelect(obj, $selected) {
        const personId = $selected.data("id")
        const personName = $selected.find(".list-group-item-title").text().trim()

        $.when(peopleList.addListItem(personName, personId)).done(() => {
          $(this).val("")
        })
      })
    })
  }

  registerViewToggles() {
    $(document)
      .on("click", "[data-action=toggle-pto-stats]:not(.active)", (e) => {
        this.toggleView(e, "pto_widget_view")
      })
      .on("click", "[data-action=toggle-view]:not(.active)", (e) => {
        this.toggleView(e, "display_view")
      })
      .on("click", "[data-action=toggle-units]:not(.active)", (e) => {
        this.toggleView(e, "display_unit")
      })
  }

  initTransactions($content) {
    this.$transactionHistory = $content
    this.sortTransactions()
    this.$transactionHistory.find("[data-action=print]").on("click", ProfilePtos.printTransactions)
    this.$transactionHistory.find("select").one("change", () => {
      this.filterTransactions()
    })
  }

  static toggleWidgetView($node, tab) {
    $node.find(".pto-widget-tab-content").removeClass("active")
    $node.find(`.pto-widget-tab-content.${tab}`).addClass("active")
    $node.find(".pto-widget-tab-links").removeClass("active")
    $node.find(`.pto-widget-tab-links.${tab}`).addClass("active")
  }

  toggleView(e, view) {
    e.preventDefault()

    const $target = $(e.target)

    if ($target.data("url")) {
      $.ajax({ url: $target.data("url"), type: "PUT" })
    }

    switch (view) {
      case "pto_widget_view":
        ProfilePtos.toggleWidgetView($target.closest(".pto-widget"), $target.data("tab"))
        break
      case "display_view":
        ProfilePtos.toggleWidgetView(this.$container, $target.data("tab"))
        this.$container.find(".toggle-pto-widget-view").toggleClass("active")
        break
      case "display_unit":
        this.$container.find(".pto-widget-unit").toggleClass("hidden")
        this.$container.find(".toggle-pto-widget-unit").toggleClass("active")
        break
      default:
        break
    }
  }

  sortTransactions() {
    this.$transactionHistory.find("table").bind("sortEnd", (e) => {
      const $table = $(e.target)
      const $target = $table.find("th.sort")
      const sortDirection = $target.hasClass("sort-up") ? "desc" : "asc"
      const url = $target.data("url")

      $table.parents("section").find("input#sort_direction").val(sortDirection)

      if (url) {
        $.ajax({
          url,
          type: "PUT",
          data: { pto: { history_sort_direction: sortDirection } },
        })
      }
    })
  }

  filterTransactions(scrollTo = true) {
    Pjax.render(this.$transactionHistory, {
      data: this.$transactionHistory.find("form").serialize(),
      scrollTo,
    })
  }

  static ptoAccountInput(name) {
    return `:input[name="pto_account[${name}]"]`
  }

  static setModifySubmit() {
    const $mainModal = $(ProfilePtos.balanceModals()).first()
    const mainModal = new Modal($mainModal)
    const $mainModalForm = mainModal.$el.find("#modify_balance_form")
    $mainModalForm.attr("data-pjax-submit", "modal-content")
    return $mainModalForm
  }

  static confirmMaxBalance() {
    const confirmModal = window.currentModal
    const $mainModalForm = ProfilePtos.setModifySubmit()
    $mainModalForm.find(ProfilePtos.ptoAccountInput("skip_max_balance_check")).val(true)
    $mainModalForm.submit()
    confirmModal.close()
  }

  static checkMaxBalance(event, modal) {
    let $form = modal.find("#modify_balance_form")
    let maxBalance = $form.find(ProfilePtos.ptoAccountInput("max_balance")).val()
    const balanceChange = parseFloat(
      $form.find(ProfilePtos.ptoAccountInput("balance_change")).val(),
    )

    if (balanceChange > 0 && maxBalance !== undefined) {
      maxBalance = parseFloat(maxBalance)
      const direction = $form.find(ProfilePtos.ptoAccountInput("change_direction")).val()
      let newBalance = parseFloat($form.find("[data-current-balance]").attr("data-current-balance"))
      if (direction === "add") {
        newBalance += balanceChange
      } else {
        newBalance -= balanceChange
      }

      if (maxBalance > 0 && newBalance > maxBalance) {
        $form.removeAttr("data-pjax-submit")
        $form = modal.find("#confirm_max_balance_form")
      }
    }
    $form.submit()
    return false
  }

  static confirmTransactionDeletion(modal) {
    $(modal).on("modal:save", (_e, modal) => {
      ProfilePtos.reloadAccounts()
      Pjax.render("section#pto-requests", { scrollTo: false })
      const rowId = modal.$el.find("#pto_transaction_row_id").val()
      window.pto.$transactionHistory.find(`tr[data-row-id=${rowId}]`).remove()
      window.pto.$transactionHistory.find("table").trigger("updateCache")
      window.location.reload()
    })
  }

  static printTransactions(e) {
    e.preventDefault()
    const noPrintSection = $("section:not(#history)")

    noPrintSection.addClass("no-print")
    window.print()
    noPrintSection.removeClass("no-print")
  }
}

ProfilePtos.OVERVIEW_TAB_MODAL = "pto-approval-requests"
ProfilePtos.PTO_TAB_MODAL = "profile-pto-request"
ProfilePtos.TIMESHEET_TAB_MODAL = "timesheet-pto-request"

window.ProfilePtos = ProfilePtos
